@tailwind base;
@tailwind components;
@tailwind utilities;

/*@layer base {*/
/*    :root {*/
/*        --color-primaryLight: rgb(255 115 179);*/
/*        !* ... *!*/
/*    }*/
/*}*/
